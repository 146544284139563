import React, { useState } from "react";
import { Seo } from "../../components/seo";
import Layout from "../../hoc/Layout/Layout";
import Booking from "../../components/Booking/Booking";
import Instructions from "../../components/Instructions/Instructions";
import Faq from "../../components/FAQ/FAQFlyttstadning";
import FlyttstadningQ from "../../components/FAQ/FlyttstadningQ";
import { SERVICE_TYPES } from "../../enums/serviceTypes";

// markup
const IndexPage = () => {
  const [bookingActive, setBookingActive] = useState(false);
  const [serviceChosen, setServiceChosen] = useState(false);
  const [ableToGoBack, setAbleToGoBack] = useState(false);
  const [finishBookingStep, setFinishBookingStep] = useState();

  return (
    <Layout
      bookingActive={bookingActive}
      serviceChosen={serviceChosen}
      ableToGoBack={ableToGoBack}
      finishBookingStep={finishBookingStep}
      setFinishBookingStep={setFinishBookingStep}
    >
      <Seo title="Flyttstädning i Göteborg | Bilda Group" />
      <Booking
        setBookingActive={setBookingActive}
        choosenService={SERVICE_TYPES.Flyttstädning}
        bgType2
        firstStep={2}
        setServiceChosen={setServiceChosen}
        setAbleToGoBack={setAbleToGoBack}
        finishBookingStep={finishBookingStep}
        setFinishBookingStep={setFinishBookingStep}
      />
      {!bookingActive && (
        <>
          <FlyttstadningQ />
          <Instructions
            // bg
            text1="Ange ditt postnummer och hur stor din bostad är."
            text2="Se pris och välj det datum du önskar få flyttstädningen utförd. Vi återkopplar om datumet finns ledigt."
            text3="Vi utför flyttstädningen så att du kan lägga all energi i ditt nya hem. Vårt professionella städteam gör ordentligt rent in i minsta detalj."
            heading1="Beräkna ditt pris"
            heading2="Få städning utförd"
            heading3="Njut av ditt nya hem"
          />
          <Faq />
          {/* <GradeSection /> */}
        </>
      )}
    </Layout>
  );
};

export default IndexPage;
